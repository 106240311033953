import { PRIVACY_POLICY_TEXTS } from "../constants/privacyPolicyTexts";
import "../css/privacyPolicy.css";

const DesktopVersion = () => {
  return (
    <div className="privacy-container">
      <h3 className="privacy-chapter-headline">
        {PRIVACY_POLICY_TEXTS.headline}
      </h3>
      <div className="privacy-wrapper">
        <p className="standard-par">
          {PRIVACY_POLICY_TEXTS.first}
          <br />
          {PRIVACY_POLICY_TEXTS.first1}
        </p>
        <p className="standard-par privacy-bold">
          {PRIVACY_POLICY_TEXTS.first2}
        </p>
        <p className="standard-par privacy-bold">
          {PRIVACY_POLICY_TEXTS.first3}
        </p>
        <p className="standard-par">{PRIVACY_POLICY_TEXTS.first4}</p>
        <p className="standard-par privacy-bold">
          {PRIVACY_POLICY_TEXTS.first5}
        </p>
        <p className="standard-par">{PRIVACY_POLICY_TEXTS.first6}</p>
        <ul className="privacy-first-section">
          <li>{PRIVACY_POLICY_TEXTS.liFirst}</li>
          <li>{PRIVACY_POLICY_TEXTS.liFirst1}</li>
          <li>{PRIVACY_POLICY_TEXTS.liFirst2}</li>
          <li>{PRIVACY_POLICY_TEXTS.liFirst3}</li>
          <li>{PRIVACY_POLICY_TEXTS.liFirst4}</li>
          <li>{PRIVACY_POLICY_TEXTS.liFirst5}</li>
          <li>{PRIVACY_POLICY_TEXTS.liFirst6}</li>
          <li>{PRIVACY_POLICY_TEXTS.liFirst7}</li>
          <li>{PRIVACY_POLICY_TEXTS.liFirst8}</li>
          <li>
            {PRIVACY_POLICY_TEXTS.liFirst9}
            <br />
            {PRIVACY_POLICY_TEXTS.liFirst9a}
            <br />
            {PRIVACY_POLICY_TEXTS.liFirst9b}
            <br />
            {PRIVACY_POLICY_TEXTS.liFirst9c}
            <br />
            {PRIVACY_POLICY_TEXTS.liFirst9d}
          </li>
          <li>{PRIVACY_POLICY_TEXTS.liFirst10}</li>
          <li>
            {PRIVACY_POLICY_TEXTS.liFirst11}
            <br />
            {PRIVACY_POLICY_TEXTS.liFirst11a}
            <br />
            {PRIVACY_POLICY_TEXTS.liFirst11b}
            <br />
            {PRIVACY_POLICY_TEXTS.liFirst11c}
          </li>
        </ul>
        <p className="standard-par">{PRIVACY_POLICY_TEXTS.second}</p>
        <p className="standard-par privacy-bold">
          {PRIVACY_POLICY_TEXTS.second1}
        </p>
        <p className="standard-par">{PRIVACY_POLICY_TEXTS.second2}</p>
        <ul className="privacy-first-section">
          <li>{PRIVACY_POLICY_TEXTS.liSecond}</li>
          <li>
            {PRIVACY_POLICY_TEXTS.liSecond1}
            <br />
            {PRIVACY_POLICY_TEXTS.liSecond1a}
          </li>
          <li>
            {PRIVACY_POLICY_TEXTS.liSecond2}
            <br />
            {PRIVACY_POLICY_TEXTS.liSecond2a}
            <br />
            {PRIVACY_POLICY_TEXTS.liSecond2b}
            <br />
            {PRIVACY_POLICY_TEXTS.liSecond2c}
          </li>
          <li>
            {PRIVACY_POLICY_TEXTS.liSecond3}
            <br />
            {PRIVACY_POLICY_TEXTS.liSecond3a}
            <br />
            {PRIVACY_POLICY_TEXTS.liSecond3b}
            <br />
            {PRIVACY_POLICY_TEXTS.liSecond3c}
          </li>
          <li>
            {PRIVACY_POLICY_TEXTS.liSecond4}
            <br />
            {PRIVACY_POLICY_TEXTS.liSecond4a}
            <br />
            {PRIVACY_POLICY_TEXTS.liSecond4b}
            <br />
            {PRIVACY_POLICY_TEXTS.liSecond4c}
          </li>
        </ul>
        <p className="standard-par">{PRIVACY_POLICY_TEXTS.third}</p>
        <p className="standard-par privacy-bold">
          {PRIVACY_POLICY_TEXTS.third1}
        </p>
        <p className="standard-par">{PRIVACY_POLICY_TEXTS.third2}</p>
        <ul className="privacy-first-section">
          <li>{PRIVACY_POLICY_TEXTS.liThird}</li>
          <li>{PRIVACY_POLICY_TEXTS.liThird1}</li>
          <li>{PRIVACY_POLICY_TEXTS.liThird2}</li>
          <li>{PRIVACY_POLICY_TEXTS.liThird3}</li>
          <li>{PRIVACY_POLICY_TEXTS.liThird4}</li>
          <li>{PRIVACY_POLICY_TEXTS.liThird5}</li>
          <li>{PRIVACY_POLICY_TEXTS.liThird6}</li>
          <li>{PRIVACY_POLICY_TEXTS.liThird7}</li>
          <li>{PRIVACY_POLICY_TEXTS.liThird8}</li>
          <li>{PRIVACY_POLICY_TEXTS.liThird9}</li>
          <li>{PRIVACY_POLICY_TEXTS.liThird10}</li>
          <li>
            {PRIVACY_POLICY_TEXTS.liThird11}
            <br />
            {PRIVACY_POLICY_TEXTS.liThird11a}
          </li>
        </ul>
        <p className="standard-par">{PRIVACY_POLICY_TEXTS.fourth}</p>
        <p className="standard-par">{PRIVACY_POLICY_TEXTS.fourth1}</p>
        <p className="standard-par">{PRIVACY_POLICY_TEXTS.fourth2}</p>
        <p className="standard-par privacy-bold">
          {PRIVACY_POLICY_TEXTS.fourth3}
        </p>
        <p className="standard-par">{PRIVACY_POLICY_TEXTS.fourth4}</p>
        <p className="standard-par privacy-bold">
          {PRIVACY_POLICY_TEXTS.fourth5}
        </p>
        <p className="standard-par">{PRIVACY_POLICY_TEXTS.fourth6}</p>
        <p className="standard-par privacy-bold">
          {PRIVACY_POLICY_TEXTS.fourth7}
        </p>
        <p className="standard-par">{PRIVACY_POLICY_TEXTS.fourth8}</p>
        <ul className="privacy-first-section">
          <li>{PRIVACY_POLICY_TEXTS.liFourth}</li>
          <li>{PRIVACY_POLICY_TEXTS.liFourth1}</li>
          <li>{PRIVACY_POLICY_TEXTS.liFourth2}</li>
          <li>{PRIVACY_POLICY_TEXTS.liFourth3}</li>
          <li>
            {PRIVACY_POLICY_TEXTS.liFourth4}
            <br />
            {PRIVACY_POLICY_TEXTS.liFourth4a}
            <br />
            {PRIVACY_POLICY_TEXTS.liFourth4b}
          </li>
        </ul>
        <p className="standard-par privacy-bold">
          {PRIVACY_POLICY_TEXTS.fifth}
        </p>
        <p className="standard-par">{PRIVACY_POLICY_TEXTS.fifth1}</p>
        <p className="standard-par privacy-bold">
          {PRIVACY_POLICY_TEXTS.fifth2}
        </p>
        <p className="standard-par">{PRIVACY_POLICY_TEXTS.fifth3}</p>
        <p className="standard-par privacy-bold">
          {PRIVACY_POLICY_TEXTS.fifth4}
        </p>
        <p className="standard-par">{PRIVACY_POLICY_TEXTS.fifth5}</p>
        <p className="standard-par">{PRIVACY_POLICY_TEXTS.fifth6}</p>
        <p className="standard-par">{PRIVACY_POLICY_TEXTS.fifth7}</p>
        <p className="standard-par privacy-bold">
          {PRIVACY_POLICY_TEXTS.fifth8}
        </p>
        <p className="standard-par">{PRIVACY_POLICY_TEXTS.fifth9}</p>
        <p className="standard-par">{PRIVACY_POLICY_TEXTS.fifth10}</p>
        <p className="standard-par privacy-bold">
          {PRIVACY_POLICY_TEXTS.fifth11}
        </p>
        <p className="standard-par">{PRIVACY_POLICY_TEXTS.fifth12}</p>
        <p className="standard-par">{PRIVACY_POLICY_TEXTS.fifth13}</p>
        <p className="standard-par privacy-bold">
          {PRIVACY_POLICY_TEXTS.fifth14}
        </p>
        <p className="standard-par">{PRIVACY_POLICY_TEXTS.fifth15}</p>
        <p className="standard-par">{PRIVACY_POLICY_TEXTS.fifth16}</p>
        <p className="standard-par">{PRIVACY_POLICY_TEXTS.fifth17}</p>
        <p className="standard-par privacy-bold">
          {PRIVACY_POLICY_TEXTS.fifth19}
        </p>
        <ul className="privacy-first-section">
          <li className="standard-par">
            <a href="mailto:info@winnerda0.com">
              {PRIVACY_POLICY_TEXTS.contact}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export const PrivayPolicy = () => {
  return <DesktopVersion />;
};
