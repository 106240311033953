import { useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "../css/info.css";
import "../css/animation.css";

import line from "../assets/line.webp";
import first from "../assets/gifs/rocket.webm";
import second from "../assets/gifs/vote.webm";
import third from "../assets/gifs/podcast.webm";
import fourth from "../assets/gifs/warp.webm";

import firstPre from "../assets/pre_gif_images/rocket.webp";
import secondPre from "../assets/pre_gif_images/vote.webp";
import thirdPre from "../assets/pre_gif_images/podcast.webp";
import fourthPre from "../assets/pre_gif_images/warp.webp";

const DesktopInfo = () => {
  const [loadFirst, setLoadFirst] = useState(0);
  const [loadSecond, setLoadSecond] = useState(0);
  const [loadThird, setLoadThird] = useState(0);
  const [loadFourth, setLoadFourth] = useState(0);

  return (
    <div id="info" className="info-container pad-hidden mob-hidden">
      <div className="info-line-wrapper">
        <img src={line} alt="line" />
      </div>
      <h3 className="info-headline">flight plan</h3>
      <div className="single-tab first-tab">
        <div>
          <h5 data-aos="fade-right">1. launch "skyward"</h5>
          <p data-aos="fade-right" data-aos-delay="500">
            We launch the "skyward" podcast and start accepting applications
            from crypto and blockchain projects looking to connect with
            established industry whales and millions of viewers
          </p>
        </div>
        { !loadFirst && <img data-aos="fade-left" src={firstPre} alt="first"/> }
        <video data-aos="fade-left" autoPlay muted loop hidden={!loadFirst}
          onLoadedData={() => setLoadFirst(true)}
        >
          <source src={first} type="video/webm" />
        </video>
      </div>
      <div className="single-tab">
        { !loadSecond && <img data-aos="fade-right" src={secondPre} alt="second"/> }
        <video data-aos="fade-right" autoPlay muted loop hidden={!loadSecond}
          onLoadedData={() => setLoadSecond(true)}
        >
          <source src={second} type="video/webm" />
        </video>
        <div>
          <h5 data-aos="fade-left">2. THE TRIBE HAS SPOKEN</h5>
          <p data-aos="fade-left" data-aos-delay="500">
            Winner DAO holders vote for the most promising and innovative
            projects that will ultimately make it on to the show using Snapshot.
          </p>
        </div>
      </div>
      <div className="single-tab">
        <div>
          <h5 data-aos="fade-right">3. ANNOUNCE THE CUT</h5>
          <p data-aos="fade-right" data-aos-delay="500">
            The finalists are announced on an episode of "skyward" a week prior
            to the show airing, giving the founders time to pre-record a 3-5 min
            presentation of their project pitch.
          </p>
        </div>
        { !loadThird && <img data-aos="fade-left" src={thirdPre} alt="third"/> }
        <video data-aos="fade-left" autoPlay muted loop hidden={!loadThird}
          onLoadedData={() => setLoadThird(true)}
        >
          <source src={third} type="video/webm" />
        </video>
      </div>
      <div className="single-tab">
        { !loadFourth && <img data-aos="fade-right" src={fourthPre} alt="fourth"/> }
        <video data-aos="fade-right" autoPlay muted loop hidden={!loadFourth}
          onLoadedData={() => setLoadFourth(true)}
        >
          <source src={fourth} type="video/webm" />
        </video>
        <div>
          <h5 data-aos="fade-left">4. ENTERING THE WARP JUMP</h5>
          <p data-aos="fade-left" data-aos-delay="500">
            During a "Warp Jump" live show, experts, aka “whales”, interview and
            negotiate with founders and decide if they are willing to put their
            funds and reputations at stake to support any of the projects.
          </p>
        </div>
      </div>
    </div>
  );
};

const PadInfo = () => {
  const [loadFirst, setLoadFirst] = useState(0);
  const [loadSecond, setLoadSecond] = useState(0);
  const [loadThird, setLoadThird] = useState(0);
  const [loadFourth, setLoadFourth] = useState(0);

  return (
    <div id="info" className="info-container-pad web-hidden mob-hidden">
      <h3 className="info-headline-pad">flight plan</h3>
      <div className="single-tab-pad">
        <div>
          <h5 data-aos="fade-right">1. launch "skyward"</h5>
          <p data-aos="fade-right" data-aos-delay="500">
            We launch the "skyward" podcast and start accepting applications
            from crypto and blockchain projects looking to connect with
            established industry whales and millions of viewers
          </p>
        </div>
        { !loadFirst && <img data-aos="fade-left" src={firstPre} alt="first"/> }
        <video data-aos="fade-left" autoPlay muted loop hidden={!loadFirst}
          onLoadedData={() => setLoadFirst(true)}
        >
          <source src={first} type="video/webm" />
        </video>
      </div>
      <div className="single-tab-pad">
        { !loadSecond && <img data-aos="fade-right" src={secondPre} alt="second"/> }
        <video data-aos="fade-right" autoPlay muted loop hidden={!loadSecond}
          onLoadedData={() => setLoadSecond(true)}
        >
          <source src={second} type="video/webm" />
        </video>
        <div>
          <h5 data-aos="fade-left">2. THE TRIBE HAS SPOKEN</h5>
          <p data-aos="fade-left" data-aos-delay="500">
            Winner DAO holders vote for the most promising and innovative
            projects that will ultimately make it on to the show using Snapshot.
          </p>
        </div>
      </div>
      <div className="single-tab-pad">
        <div>
          <h5 data-aos="fade-right">3. ANNOUNCE THE CUT</h5>
          <p data-aos="fade-right" data-aos-delay="500">
            The finalists are announced on an episode of "skyward" a week prior
            to the show airing, giving the founders time to pre-record a 3-5 min
            presentation of their project pitch.
          </p>
        </div>
        { !loadThird && <img data-aos="fade-left" src={thirdPre} alt="third"/> }
        <video data-aos="fade-left" autoPlay muted loop hidden={!loadThird}
          onLoadedData={() => setLoadThird(true)}
        >
          <source src={third} type="video/webm" />
        </video>
      </div>
      <div className="single-tab-pad">
        { !loadFourth && <img data-aos="fade-right" src={fourthPre} alt="fourth"/> }
        <video data-aos="fade-right" autoPlay muted loop hidden={!loadFourth}
          onLoadedData={() => setLoadFourth(true)}
        >
          <source src={fourth} type="video/webm" />
        </video>
        <div>
          <h5 data-aos="fade-left">4. ENTERING THE WARP JUMP</h5>
          <p data-aos="fade-left" data-aos-delay="500">
            During a "Warp Jump" live show, experts, aka “whales”, interview and
            negotiate with founders and decide if they are willing to put their
            funds and reputations at stake to support any of the projects.
          </p>
        </div>
      </div>
    </div>
  );
};

const MobInfo = () => {
  const [loadFirst, setLoadFirst] = useState(0);
  const [loadSecond, setLoadSecond] = useState(0);
  const [loadThird, setLoadThird] = useState(0);
  const [loadFourth, setLoadFourth] = useState(0);

  return (
    <div id="info" className="info-container-mob non-mob-hidden">
      <h3 className="info-headline-mob">flight plan</h3>
      <div className="single-tab-mob">
        <div>
          <h5 data-aos="fade-right">1. launch "skyward"</h5>
          <p data-aos="fade-right" data-aos-delay="500">
            We launch the "skyward" podcast and start accepting applications
            from crypto and blockchain projects looking to connect with
            established industry whales and millions of viewers
          </p>
        </div>
        { !loadFirst && <img data-aos="fade-left" src={firstPre} alt="first"/> }
        <video data-aos="fade-left" autoPlay muted loop hidden={!loadFirst}
          onLoadedData={() => setLoadFirst(true)}
        >
          <source src={first} type="video/webm" />
        </video>
      </div>
      <div className="single-tab-mob">
        <div>
          <h5 data-aos="fade-right">2. THE TRIBE HAS SPOKEN</h5>
          <p data-aos="fade-right" data-aos-delay="500">
            Winner DAO holders vote for the most promising and innovative
            projects that will ultimately make it on to the show using Snapshot.
          </p>
        </div>
        { !loadSecond && <img data-aos="fade-right" src={secondPre} alt="second"/> }
        <video data-aos="fade-right" autoPlay muted loop hidden={!loadSecond}
          onLoadedData={() => setLoadSecond(true)}
        >
          <source src={second} type="video/webm" />
        </video>
      </div>
      <div className="single-tab-mob">
        <div>
          <h5 data-aos="fade-right">3. ANNOUNCE THE CUT</h5>
          <p data-aos="fade-right" data-aos-delay="500">
            The finalists are announced on an episode of "skyward" a week prior
            to the show airing, giving the founders time to pre-record a 3-5 min
            presentation of their project pitch.
          </p>
        </div>
        { !loadThird && <img data-aos="fade-left" src={thirdPre} alt="third"/> }
        <video data-aos="fade-left" autoPlay muted loop hidden={!loadThird}
          onLoadedData={() => setLoadThird(true)}
        >
          <source src={third} type="video/webm" />
        </video>
      </div>
      <div className="single-tab-mob">
        <div>
          <h5 data-aos="fade-right">4. ENTERING THE WARP JUMP</h5>
          <p data-aos="fade-right" data-aos-delay="500">
            During a "Warp Jump" live show, experts, aka “whales”, interview and
            negotiate with founders and decide if they are willing to put their
            funds and reputations at stake to support any of the projects.
          </p>
        </div>
        { !loadFourth && <img data-aos="fade-right" src={fourthPre} alt="fourth"/> }
        <video data-aos="fade-right" autoPlay muted loop hidden={!loadFourth}
          onLoadedData={() => setLoadFourth(true)}
        >
          <source src={fourth} type="video/webm" />
        </video>
      </div>
    </div>
  );
};

export const Info = () => {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);

  return (
    <>
      <DesktopInfo/>
      <PadInfo/>
      <MobInfo/>
    </>
  );
};
