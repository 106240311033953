import { useState, useRef } from "react";
import useOutsideClick from "@rooks/use-outside-click";
import { DiscordIcon, HOME_PATH } from "../constants";
import { useLocation } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
// CSS
import "../css/header.css";

// Images
import menu from "../assets/menu.webp";
import discord from "../assets/discord.webp";
import smallLogo from "../assets/smallLogo.webp";

const getFirstTabYForRocket = () =>
  Array.from(
    document.getElementsByClassName("single-tab")
  )[1].getBoundingClientRect().top - document.body.getBoundingClientRect().top;

const animateRocketTo = (y) =>
  (document.getElementById("rocket").style.top = `${y}px`);

const DesktopHeader = ({ headerHover, setHeaderHover, location }) => {
  return (
    <div
      className={
        location === HOME_PATH
          ? "header-container-main pad-hidden mob-hidden"
          : "header-container pad-hidden mob-hidden"
      }
    >
      <a href="/">
        <img
          className={
            location === HOME_PATH ? "header-logo-main" : "header-logo"
          }
          src={smallLogo}
          alt="logo"
        />
      </a>
      <div
        className={
          location === HOME_PATH
            ? "header-nav-wrapper-main"
            : "header-nav-wrapper"
        }
      >
        <Link
          to="/#info"
          // onClick={() => animateRocketTo(getFirstTabYForRocket())}
        >
          How it works?
        </Link>
        <Link to="/#team">team</Link>
        <a
          href="https://discord.gg/q95aaU5nZ6"
          target="_blank"
          rel="noreferrer"
        >
          <DiscordIcon
            onMouseOver={() => {
              setHeaderHover(true);
            }}
            onMouseLeave={() => setHeaderHover(false)}
            height="1.2vw"
            fill={headerHover ? "#7289D9" : "#fff"}
          />
        </a>
      </div>
    </div>
  );
};

const PadHeader = ({ headerHover, setHeaderHover }) => {
  return (
    <div className="header-container-pad web-hidden mob-hidden">
      <a href="/">
        <img className="header-logo-pad" src={smallLogo} alt="logo" />
      </a>
      <div className="header-nav-wrapper-pad">
        <Link
          onClick={() => animateRocketTo(getFirstTabYForRocket())}
          to="/#info"
        >
          How it works?
        </Link>
        <Link to="/#team">team</Link>
        <a
          href="https://discord.gg/q95aaU5nZ6"
          target="_blank"
          rel="noreferrer"
        >
          <DiscordIcon
            onMouseOver={() => {
              setHeaderHover(true);
            }}
            onMouseLeave={() => setHeaderHover(false)}
            height="2vw"
            fill={headerHover ? "#7289D9" : "#fff"}
          />
        </a>
      </div>
    </div>
  );
};

const MobHeader = ({ setMenuModal, modalRef }) => {
  return (
    <div className="header-container-mob non-mob-hidden">
      <a href="/">
        <img className="header-logo-mob" src={smallLogo} alt="logo" />
      </a>
      <img
        onClick={() => setMenuModal(true)}
        className="header-menu-mob"
        src={menu}
        alt="open menu"
      />
    </div>
  );
};

const MenuModal = ({ modalRef, setMenuModal }) => {
  return (
    <div ref={modalRef} className="header-menu-modal-container non-mob-hidden">
      <Link
        onClick={() => {
          animateRocketTo(getFirstTabYForRocket());
          setMenuModal(false);
        }}
        to="/#info"
      >
        How it works?
      </Link>
      <span className="header-menu-modal-line"></span>
      <Link onClick={() => setMenuModal(false)} to="/#team">
        team
      </Link>
      <span className="header-menu-modal-line"></span>
      <img src={discord} alt="discord logo" />
    </div>
  );
};

export const Header = () => {
  const [menuModal, setMenuModal] = useState(false);
  const [headerHover, setHeaderHover] = useState(false);
  const { pathname: location } = useLocation();

  // modal outside click handler
  const modalRef = useRef();
  let num = 0;
  const modalHandler = () => {
    if (num === 0) return num++;
    setMenuModal(false);
    num = 0;
  };
  useOutsideClick(modalRef, modalHandler);

  return (
    <>
      <DesktopHeader
        location={location}
        headerHover={headerHover}
        setHeaderHover={setHeaderHover}
      />
      <PadHeader headerHover={headerHover} setHeaderHover={setHeaderHover} />
      <MobHeader modalRef={modalRef} setMenuModal={setMenuModal} />
      {menuModal && (
        <MenuModal setMenuModal={setMenuModal} modalRef={modalRef} />
      )}
    </>
  );
};
