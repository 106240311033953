import { useEffect, useState } from "react";
import axios from 'axios';
import FileSaver from 'file-saver';

import "../css/press.css";

// Images

import moonSatelite from "../assets/press/planet.webp";
import downloadArrow from "../assets/press/downloadArrow.png";
import green from "../assets/brand colors/green.webp";
import white from "../assets/brand colors/white.webp";
import whiteHov from "../assets/brand colors/whiteHov.webp";
import black from "../assets/brand colors/black.webp";
import pink from "../assets/brand colors/pink.webp";
import greenHov from "../assets/brand colors/greenHov.webp";
import pinkHov from "../assets/brand colors/pinkHov.webp";
import blackHov from "../assets/brand colors/blackHov.webp";

const DOMAIN = "https://warp-back-1-o5k7we4dja-lm.a.run.app";
const ENDPOINTS = {
  downloadAll: "/download-all-logos",
  getLogos: "/get_logos",
  getMembers: "/get_press_members",
}

// const posts = [
//   {
//     date: "2019-05-05",
//     header: "Tink announces partnership with NatWes",
//     description:
//       "Swedish fintech Tink today launches their API platform, granting developers in companies of all sizes, across any sector, access to Tink’s technology. This will allow developers to use Tink’s Account Aggregation and Categorisation products via a self-serving platform,  Swedish fintech Tink today launches their API platform, granting developers in companies of all sizes, across any sector, access to Tink’s technology. This will allow developers to use Tink’s Account Aggregation and Categorisation products via a self-serving platform, ",
//   },
//   {
//     date: "2019-05-05",
//     header: "Tink announces partnership with NatWes",
//     description:
//       "Swedish fintech Tink today launches their API platform, granting developers in companies of all sizes, across any sector, access to Tink’s technology. This will allow developers to use Tink’s Account Aggregation and Categorisation products via a self-serving platform,  Swedish fintech Tink today launches their API platform, granting developers in companies of all sizes, across any sector, access to Tink’s technology. This will allow developers to use Tink’s Account Aggregation and Categorisation products via a self-serving platform, ",
//   },
//   {
//     date: "2019-05-05",
//     header: "Tink announces partnership with NatWes",
//     description:
//       "Swedish fintech Tink today launches their API platform, granting developers in companies of all sizes, across any sector, access to Tink’s technology. This will allow developers to use Tink’s Account Aggregation and Categorisation products via a self-serving platform,  Swedish fintech Tink today launches their API platform, granting developers in companies of all sizes, across any sector, access to Tink’s technology. This will allow developers to use Tink’s Account Aggregation and Categorisation products via a self-serving platform, ",
//   },
//   {
//     date: "2019-05-05",
//     header: "Tink announces partnership with NatWes",
//     description:
//       "Swedish fintech Tink today launches their API platform, granting developers in companies of all sizes, across any sector, access to Tink’s technology. This will allow developers to use Tink’s Account Aggregation and Categorisation products via a self-serving platform,  Swedish fintech Tink today launches their API platform, granting developers in companies of all sizes, across any sector, access to Tink’s technology. This will allow developers to use Tink’s Account Aggregation and Categorisation products via a self-serving platform, ",
//   },
//   {
//     date: "2019-05-05",
//     header: "Tink announces partnership with NatWes",
//     description:
//       "Swedish fintech Tink today launches their API platform, granting developers in companies of all sizes, across any sector, access to Tink’s technology. This will allow developers to use Tink’s Account Aggregation and Categorisation products via a self-serving platform,  Swedish fintech Tink today launches their API platform, granting developers in companies of all sizes, across any sector, access to Tink’s technology. This will allow developers to use Tink’s Account Aggregation and Categorisation products via a self-serving platform, ",
//   },
//   {
//     date: "2019-05-05",
//     header: "Tink announces partnership with NatWes",
//     description:
//       "Swedish fintech Tink today launches their API platform, granting developers in companies of all sizes, across any sector, access to Tink’s technology. This will allow developers to use Tink’s Account Aggregation and Categorisation products via a self-serving platform,  Swedish fintech Tink today launches their API platform, granting developers in companies of all sizes, across any sector, access to Tink’s technology. This will allow developers to use Tink’s Account Aggregation and Categorisation products via a self-serving platform, ",
//   },
// ];

// const team = [
//   {
//     name: "David Mirelli",
//     position: "Strategy & Coordination",
//     img: David,
//     bio: "Investor and entrepreneur 15+ years | Multiple 1m+ user projects",
//   },
//   { name: "Armand Van Aswegen", position: "PR", img: Armand, bio: "Copywriter & Journalist with 10+ years of experience" },
// ];

const brandColors = [
  { colorId: "#41DFC9", hovered: greenHov, img: green, name: "green" },
  { colorId: "#FF206E", hovered: pinkHov, img: pink, name: "pink" },
  { colorId: "#131313", hovered: blackHov, img: black, name: "black" },
  { colorId: "#FFFFFF", hovered: whiteHov, img: white, name: "white" },
];


const downloadFile = async (name, type, url) => {
  await fetch(url)
    .then(res => res.blob())
    .then((blob) => {
        FileSaver.saveAs(blob, name + '.' + type);
    })
}
const downloadPack = async (name, type) => {
  await axios
    .post(DOMAIN + ENDPOINTS.downloadAll)
    .then(response => {
      fetch(response.data.url)
      .then(res => res.blob())
      .then((blob) => {
          FileSaver.saveAs(blob, name + '.' + type);
      })
    })
}

const DesktopVersion = ({
  colorHover,
  setColorHover,
  colorCopied,
  setColorCopied,
  logos,
  members
}) => {
  return (
    <div className="press-container pad-hidden">
      {/* Intro */}
      <div className="press-intro">
        <div className="press-intro-desc">
          <h3 className="chapter-headline">Press</h3>
          <p>
            Connect yourself with all the latest news, analyses, and highlights
            from WarpJump and get some sneak peeks from the show. For media
            inquiries, beam us an email at{" "}
            <a href="mailto:info@winnerda0.com">pr@winnerdao.com</a>
          </p>
        </div>
        <img src={moonSatelite} alt="logo" />
      </div>

      {/* Posts */}
      {/* <div className="posts-container">
        <h3 className="chapter-headline">Press Releases</h3>
        <div className="posts-wrapper">
          {posts.map((value, index) => (
            <div className="single-post" key={index}>
              <p className="post-date">{value.date}</p>
              <p className="post-headline">{value.header}</p>
              <p className="post-description">{value.description}</p>
              <p className="cursor-pointer">Read more </p>
              <span></span>
            </div>
          ))}
        </div>
        <button className="posts-btn opacityZero">Load More</button>
      </div> */}

      {/* Team Members */}
      <div className="press-team-container">
        <h3 className="chapter-headline">Team Members</h3>
        <div className="press-team-wrapper">
          {members.map((value, index) => (
            <div className="press-single-member" key={index}>
              <img src={value.img} alt="member pic" />
              <p className="press-member-name">{value.name}</p>
              <p className="press-member-role">{value.position}</p>
              <p className="press-member-bio">{value.bio}</p>
              <div className="press-member-download-wrapper">
                <div className="pres-logo-download-s" onClick={(e) => downloadFile(value.name, "png", value.png)}>
                  <p>Download photo and Bio </p>
                  <img src={downloadArrow} alt="download" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* brand colors */}
      <div className="brand-colors-container">
        <h3 className="chapter-headline">Brand Colors</h3>
        <div className="brand-colors-wrapper">
          {brandColors.map((value, index) => (
            <div className="brand-single-color" key={index}>
              <img
                loading="lazy"
                onMouseEnter={() => setColorHover(value.name)}
                onMouseLeave={() => setColorHover("none")}
                onClick={() => {
                  navigator.clipboard.writeText(value.colorId);
                  setColorCopied(value.name);
                }}
                src={colorHover === value.name ? value.hovered : value.img}
                alt="color pic"
              />
              <p
                className={
                  colorCopied === value.name
                    ? "brand-colors-copied-success"
                    : "brand-colors-copied-success opacityZero"
                }
              >
                copied
              </p>
              <p className="brand-color-id">{value.colorId}</p>
            </div>
          ))}
        </div>
      </div>
      {/* logos */}
      <div className="press-logos-container">
        <h3 className="chapter-headline">Logos</h3>
        <div className="press-logos-wrapper">
          {logos.map((value, index) => (
            <div className="press-single-logo" key={index}>
              <img
                className={
                  value.backg === "white" ? "logo-white-background" : ""
                }
                loading="lazy"
                key={index}
                src={value.img}
                alt="logo"
              />
              <div className="press-logo-det">
                <p className="press-logo-name">{value.name}</p>
                <div className="press-logo-download-wrapper">
                  <div className="pres-logo-download-s" onClick={(e) => downloadFile(value.base_name, "png", value.png)}>
                    <p>PNG</p>
                    <img src={downloadArrow} alt="download" />
                  </div>
                  <div className="pres-logo-download-s" onClick={(e) => downloadFile(value.base_name, "pdf", value.pdf)}>
                    <p>PDF</p>
                    <img src={downloadArrow} alt="download" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <button className="press-logo-download-all" onClick={(e) => downloadPack("Warp Jump Logos", "zip")}>Download logo pack</button>
      </div>
    </div>
  );
};

const PadVersion = ({
  colorHover,
  setColorHover,
  colorCopied,
  setColorCopied,
  logos,
  members
}) => {
  return (
    <div className="press-container web-hidden mob-hidden">
      {/* Intro */}
      <div className="press-intro-pad">
        <div className="press-intro-desc-pad">
          <h3 className="chapter-headline-pad">Press</h3>
          <p>
            Connect yourself with all the latest news, analyses, and highlights
            from WarpJump and get some sneak peeks from the show. For media
            inquiries, beam us an email at{" "}
            <a href="mailto:info@winnerda0.com">pr@winnerdao.com</a>
          </p>
        </div>
        <img src={moonSatelite} alt="logo" />
      </div>

      {/* Posts */}
      {/* <div className="posts-container-pad">
        <h3 className="chapter-headline-pad">Press Releases</h3>
        <div className="posts-wrapper-pad">
          {posts.map((value, index) => (
            <div className="single-post-pad" key={index}>
              <p className="post-date-pad">{value.date}</p>
              <p className="post-headline-pad">{value.header}</p>
              <p className="post-description-pad">{value.description}</p>
              <p className="cursor-pointer">Read more </p>
              <span></span>
            </div>
          ))}
        </div>
        <button className="posts-btn-pad opacityZero">Load More</button>
      </div> */}

      {/* Team Members */}
      <div className="press-team-container-pad">
        <h3 className="chapter-headline-pad">Team Members</h3>
        <div className="press-team-wrapper-pad">
          {members.map((value, index) => (
            <div className="press-single-member-pad" key={index}>
              <img src={value.img} alt="member pic" />
              <p className="press-member-name-pad">{value.name}</p>
              <p className="press-member-role-pad">{value.position}</p>
              <p className="press-member-bio-pad">{value.bio}</p>
              <div className="press-member-download-wrapper-pad">
                <div className="pres-logo-download-s-pad" onClick={(e) => downloadFile(value.name, "png", value.png)}>
                  <p>Download photo and Bio </p>
                  <img src={downloadArrow} alt="download" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* brand colors */}
      <div className="brand-colors-container">
        <h3 className="chapter-headline-pad">Brand Colors</h3>
        <div className="brand-colors-wrapper">
          {brandColors.map((value, index) => (
            <div className="brand-single-color" key={index}>
              <img
                loading="lazy"
                onMouseEnter={() => setColorHover(value.name)}
                onMouseLeave={() => setColorHover("none")}
                onClick={() => {
                  navigator.clipboard.writeText(value.colorId);
                  setColorCopied(value.name);
                }}
                src={colorHover === value.name ? value.hovered : value.img}
                alt="color pic"
              />
              <p
                className={
                  colorCopied === value.name
                    ? "brand-colors-copied-success"
                    : "brand-colors-copied-success opacityZero"
                }
              >
                copied
              </p>
              <p className="brand-color-id">{value.colorId}</p>
            </div>
          ))}
        </div>
      </div>
      {/* logos */}
      <div className="press-logos-container-pad">
        <h3 className="chapter-headline-pad">Logos</h3>
        <div className="press-logos-wrapper-pad">
          {logos.map((value, index) => (
            <div className="press-single-logo-pad" key={index}>
              <img
                className={
                  value.backg === "white" ? "logo-white-background-pad" : ""
                }
                loading="lazy"
                key={index}
                src={value.img}
                alt="logo "
              />
              <div className="press-logo-det-pad">
                <p className="press-logo-name-pad">{value.name}</p>
                <div className="press-logo-download-wrapper-pad">
                  <div className="pres-logo-download-s-pad" onClick={(e) => downloadFile(value.base_name, "png", value.png)}>
                    <p>PNG</p>
                    <img src={downloadArrow} alt="download" />
                  </div>
                  <div className="pres-logo-download-s-pad" onClick={(e) => downloadFile(value.base_name, "pdf", value.pdf)}>
                    <p>PDF</p>
                    <img src={downloadArrow} alt="download" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <button className="press-logo-download-all-pad" onClick={(e) => downloadPack("Warp Jump Logos", "zip")}>
          Download logo pack
        </button>
      </div>
    </div>
  );
};

const MobVersion = ({
  colorHover,
  setColorHover,
  colorCopied,
  setColorCopied,
  logos,
  members
}) => {
  return (
    <div className="press-container non-mob-hidden">
      {/* Intro */}
      <div className="press-intro-mob">
        <h3 className="chapter-headline-pad">Press</h3>
        <div className="press-intro-desc-mob">
          <img src={moonSatelite} alt="logo" />
          <p>
            Connect yourself with all the latest news, analyses, and highlights
            from WarpJump and get some sneak peeks from the show. For media
            inquiries, beam us an email at{" "}
            <a href="mailto:info@winnerda0.com">pr@winnerdao.com</a>
          </p>
        </div>
      </div>

      {/* Posts */}
      {/* <div className="posts-container-mob">
        <h3 className="chapter-headline-pad">Press Releases</h3>
        <div className="posts-wrapper-mob">
          {posts.map((value, index) => (
            <div className="single-post-mob" key={index}>
              <p className="post-date-mob">{value.date}</p>
              <p className="post-headline-mob">{value.header}</p>
              <p className="post-description-mob">{value.description}</p>
              <p className="cursor-pointer">Read more </p>
              <span></span>
            </div>
          ))}
        </div>
        <button className="posts-btn-mob opacityZero">Load More</button>
      </div> */}

      {/* Team Members */}
      <div className="press-team-container-mob">
        <h3 className="chapter-headline-pad">Team Members</h3>
        <div className="press-team-wrapper-mob">
          {members.map((value, index) => (
            <div className="press-single-member-mob" key={index}>
              <img src={value.img} alt="member pic" />
              <p className="press-member-name-mob">{value.name}</p>
              <p className="press-member-role-mob">{value.position}</p>
              <p className="press-member-bio-mob">{value.bio}</p>
              <div className="press-member-download-wrapper-mob">
                <div className="pres-logo-download-s-mob" onClick={(e) => downloadFile(value.name, "png", value.png)}>
                  <p>Download photo and Bio </p>
                  <img src={downloadArrow} alt="download" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* brand colors */}
      <div className="brand-colors-container-mob">
        <h3 className="chapter-headline-pad">Brand Colors</h3>
        <div className="brand-colors-wrapper-mob">
          {brandColors.map((value, index) => (
            <div className="brand-single-color-mob" key={index}>
              <img
                loading="lazy"
                onMouseEnter={() => setColorHover(value.name)}
                onMouseLeave={() => setColorHover("none")}
                onClick={() => {
                  navigator.clipboard.writeText(value.colorId);
                  setColorCopied(value.name);
                }}
                src={colorHover === value.name ? value.hovered : value.img}
                alt="color pic"
              />
              <p
                className={
                  colorCopied === value.name
                    ? "brand-colors-copied-success"
                    : "brand-colors-copied-success opacityZero"
                }
              >
                copied
              </p>
              <p className="brand-color-id-mob">{value.colorId}</p>
            </div>
          ))}
        </div>
      </div>
      {/* logos */}
      <div className="press-logos-container-mob">
        <h3 className="chapter-headline-pad">Logos</h3>
        <div className="press-logos-wrapper-mob">
          {logos.map((value, index) => (
            <div className="press-single-logo-mob" key={index}>
              <img
                className={
                  value.backg === "white" ? "logo-white-background-mob" : ""
                }
                loading="lazy"
                key={index}
                src={value.img}
                alt="logo"
              />
              <div className="press-logo-det-mob">
                <p className="press-logo-name-mob">{value.name}</p>
                <div className="press-logo-download-wrapper-mob">
                  <div className="pres-logo-download-s-mob" onClick={(e) => downloadFile(value.base_name, "png", value.png)}>
                    <p>PNG</p>
                    <img src={downloadArrow} alt="download" />
                  </div>
                  <div className="pres-logo-download-s-mob" onClick={(e) => downloadFile(value.base_name, "pdf", value.pdf)}>
                    <p>PDF</p>
                    <img src={downloadArrow} alt="download" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <button className="press-logo-download-all-mob" onClick={(e) => downloadPack("Warp Jump Logos", "zip")}>
          Download logo pack
        </button>
      </div>
    </div>
  );
};

export const Press = () => {
  const [colorHover, setColorHover] = useState("none");
  const [colorCopied, setColorCopied] = useState("none");
  const [logos, setLogos] = useState([]);
  const [members, setMembers] = useState([]);

  const fetchData = async () => {
    axios
      .get(DOMAIN + ENDPOINTS.getLogos)
      .then(function (response) {
        setLogos(response.data.logos); 
      });

    axios
      .get(DOMAIN + ENDPOINTS.getMembers)
      .then(function (response) {
        setMembers(response.data.members); 
      });
  };

  useEffect(() => {
    fetchData();
    const timer = setTimeout(() => {
      setColorCopied("none");
    }, 2000);
    return () => clearTimeout(timer);
  }, [colorCopied, setColorCopied]);

  return (
    <>
      <DesktopVersion
        colorCopied={colorCopied}
        setColorCopied={setColorCopied}
        colorHover={colorHover}
        setColorHover={setColorHover}
        logos={logos}
        members={members}
      />
      <PadVersion
        colorCopied={colorCopied}
        setColorCopied={setColorCopied}
        colorHover={colorHover}
        setColorHover={setColorHover}
        logos={logos}
        members={members}
      />
      <MobVersion
        colorCopied={colorCopied}
        setColorCopied={setColorCopied}
        colorHover={colorHover}
        setColorHover={setColorHover}
        logos={logos}
        members={members}
      />
    </>
  );
};
