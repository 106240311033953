import { useState } from "react";
import axios from "axios";
import { Route, Routes, useLocation } from "react-router-dom";

import "./css/animation.css";
import {
  Header,
  Intro,
  Footer,
  Info,
  Team,
  Press,
  PrivayPolicy,
} from "./components";
import { HOME_PATH, PRESS_PATH, PRIVACY_PATH } from "./constants";

import background from "./assets/background.webp";
import pressBackground from "./assets/press/press_background.webp";

import rocket from "./assets/rocket.webp";

const animateRocketTo = (y) => {
  document.getElementById("rocket").style.top = `${y}px`;
};

let lastFly = -1;

const scrolled = (y) => {
  const tabs = Array.from(document.getElementsByClassName("single-tab")).map(
    (e) => ({
      windowTop: e.getBoundingClientRect().top,
      bodyTop:
        e.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top,
    })
  );
  const H = window.innerHeight;
  const Herr = H * 0.05;

  for (let i = 0; i < tabs.length; i++) {
    if (
      tabs[i].windowTop > H / 2 - Herr &&
      tabs[i].windowTop < H / 2 + Herr &&
      lastFly !== i
    ) {
      lastFly = i;
      animateRocketTo(tabs[i].bodyTop);
    }
  }
};

let ticking = false;
document.addEventListener("scroll", (e) => {
  if (!ticking) {
    window.requestAnimationFrame(() => {
      scrolled(window.scrollY);
      ticking = false;
    });

    ticking = true;
  }
});

function App() {  
  const responseColor = {
    OK: "#ACD1AF",
    ERROR: "#F47174",
  };
  const { pathname: location } = useLocation();
  const [email, setEmail] = useState("");
  const [subscribed, setSubscribed] = useState(false);
  const [subscribtionColor, setSubscribtionColor] = useState("");
  const [subscribtionText, setSubscribtionText] = useState("");

  const subscribe = async () => {
    await axios
      .get(
        "https://us-central1-warmpjump.cloudfunctions.net/addEmail?email=" +
          email
      )
      .then(function (response) {
        setSubscribtionColor(responseColor[response.data.status]);
        setSubscribtionText(response.data.message);
        setSubscribed(true);
        setTimeout(() => {
          setSubscribed(false);
          setSubscribtionText("");
          setSubscribtionColor("");
        }, 5000);
      });
  };

  return (
    <>
      <div className="content-container">
        <img
          src={location === PRESS_PATH ? pressBackground : background}
          alt="background"
          className={
            location === PRESS_PATH
              ? "press-background"
              : location === PRIVACY_PATH
              ? "privacy-background"
              : "main-background"
          }
        />

        <Header />
        <Routes>
          <Route
            path={HOME_PATH}
            element={
              <>
                {" "}
                <img
                  className="pad-hidden"
                  id="rocket"
                  style={{
                    width: "3.65vw",
                    position: "absolute",
                    left: "3.24vw",
                    top: "89vh",
                    transition: "top 0.5s ease-in-out",
                    zIndex: 10,
                  }}
                  src={rocket}
                  alt="rocket"
                />
                <Intro
                  email={email}
                  setEmail={setEmail}
                  subscribe={subscribe}
                  subscribed={subscribed}
                  subscribtionColor={subscribtionColor}
                  subscribtionText={subscribtionText}
                />
                <Info />
                <Team />
              </>
            }
          />
          <Route path={PRESS_PATH} element={<Press />} />
          <Route path={PRIVACY_PATH} element={<PrivayPolicy />} />
        </Routes>
        <Footer
          email={email}
          setEmail={setEmail}
          subscribe={subscribe}
          subscribed={subscribed}
          subscribtionColor={subscribtionColor}
          subscribtionText={subscribtionText}
        />
      </div>
    </>
  );
}

export default App;
