import "../css/intro.css";
import "../css/animation.css";

import largeLogo from "../assets/largeLogo.webp";
import mobLogo from "../assets/mobLogo.webp";
import mobRocket from "../assets/mobRocket.webp";
import video from "../assets/video.mp4";

const DesktopIntro = ({ setEmail, email, subscribe }) => {
  return (
    <>
      <video
        className="video-Background pad-hidden mob-hidden"
        autoPlay
        muted
        loop
      >
        <source src={video} type="video/mp4" />
      </video>
      <div className="video-overlay pad-hidden"></div>
      <div className="content-wrapper pad-hidden">
        <img className="large-logo" src={largeLogo} alt="logo" />
        <p className="content-description">
          We're developing the first TV show dedicated to Web3 and blockchain
          that connects established crypto whales and top blockchain innovators
          in search of advisers. Want to help us 'warp jump' Web3 into
          mainstream?
        </p>
        <a
          href="https://discord.gg/q95aaU5nZ6"
          target="_blank"
          rel="noreferrer"
        >
          <button className="blue-button">join us</button>
        </a>
        {/* <div className="button-wrapper-main">
          <input
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            value={email}
            type="text"
            name="email"
            placeholder="youremail@email.com"
          />
          <button className="subscribe-button-main" onClick={subscribe}>
            subscribe
          </button>
        </div> */}
      </div>
      <span className="single-tab rocket-span"></span>
    </>
  );
};

const PadIntro = () => {
  return (
    <div className="content-wrapper-pad web-hidden mob-hidden">
      <img className="large-logo-pad" src={largeLogo} alt="logo" />
      <p className="content-description-pad">
        We're developing the first TV show dedicated to Web3 and blockchain that
        connects established crypto whales and top blockchain innovators in
        search of advisers. Want to help us 'warp jump' Web3 into mainstream?
      </p>
      <a href="https://discord.gg/q95aaU5nZ6" target="_blank" rel="noreferrer">
        <button className="blue-button-pad">join us</button>
      </a>
      <video className="video-Background-pad" autoPlay muted loop>
        <source src={video} type="video/mp4" />
      </video>
    </div>
  );
};

const MobIntro = () => {
  return (
    <div className="content-wrapper-mob non-mob-hidden">
      <img className="large-logo-mob" src={mobLogo} alt="logo" />
      <p className="content-description-mob">
        We're developing the first TV show dedicated to Web3 and blockchain that
        connects established crypto whales and top blockchain innovators in
        search of advisers. Want to help us 'warp jump' Web3 into mainstream?
      </p>
      <a href="https://discord.gg/q95aaU5nZ6" target="_blank" rel="noreferrer">
        <button className="blue-button-mob">join us</button>
      </a>
      <img className="mob-rocket" src={mobRocket} alt="rocket" />
    </div>
  );
};

export const Intro = ({
  email,
  setEmail,
  subscribe,
  subscribed,
  subscribtionColor,
  subscribtionText,
}) => {
  return (
    <>
      <DesktopIntro email={email} setEmail={setEmail} subscribe={subscribe} />
      <PadIntro />
      <MobIntro />
    </>
  );
};
