import { useRef, useState } from "react";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "../css/team.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// Team Default Images
import Anna from "../assets/team/Anna.webp";
import Armand from "../assets/team/Armand.webp";
import DavidZ from "../assets/team/David Z.webp";
import David from "../assets/team/David.webp";
import Gari from "../assets/team/Gari.webp";
import Giorgi from "../assets/team/Giorgi.webp";
import Konstantin from "../assets/team/Konstantin.webp";
import Mariam from "../assets/team/Mariam.webp";
import Nikoloz from "../assets/team/Nikoloz.webp";
import Tako from "../assets/team/Tako.webp";
import Uno from "../assets/team/Uno.webp";
import Lasha from "../assets/team/Lasha.webp";
import teamArrow from "../assets/team/teamArrow.webp";

// Team Hover Images
import AnnaHover from "../assets/team_hover/Anna_hover.webp";
import ArmandHover from "../assets/team_hover/Armand_hover.webp";
import DavidZHover from "../assets/team_hover/David Z_hover.webp";
import DavidHover from "../assets/team_hover/David_hover.webp";
import GariHover from "../assets/team_hover/Gari_hover.webp";
import GiorgiHover from "../assets/team_hover/Giorgi_hover.webp";
import KonstantinHover from "../assets/team_hover/Konstantin_hover.webp";
import MariamHover from "../assets/team_hover/Mariam_hover.webp";
import NikolozHover from "../assets/team_hover/Nikoloz_hover.webp";
import TakoHover from "../assets/team_hover/Tako_hover.webp";
import UnoHover from "../assets/team_hover/Uno_hover.webp";
import LashaHover from "../assets/team_hover/Lasha_hover.webp";

// import drag from "../assets/drag.png";

const team = [
  {
    image: David,
    hoverImage: DavidHover,
    fullname: "David",
    proffesion: "Strategy & Coordination",
    description: `Investor and entrepreneur 15+ years | Multiple 1m+ user projects`,
  },
  {
    image: Lasha,
    hoverImage: LashaHover,
    fullname: "Lasha",
    proffesion: "Production & Set Design",
    description: `Producer, Art Director | Shark Tank Georgia, Fast and Furious 9`,
  },
  {
    image: Konstantin,
    hoverImage: KonstantinHover,
    fullname: "Konstantin",
    proffesion: "PR & Marketing Strategy",
    description: `Founder of 20+ successful projects | CEO of a content marketing agency`,
  },
  {
    image: Anna,
    hoverImage: AnnaHover,
    fullname: "Anna",
    proffesion: "Creative Direction",
    description: `Infinitely creative - founded clothing companies, designed multiple clothing and jewelry lines.`,
  },
  {
    image: Nikoloz,
    hoverImage: NikolozHover,
    fullname: "Nikoloz",
    proffesion: "Blockchain Strategy",
    description: `Medals in multiple informatics Olympiads and programming competitions. Interned at Google for 3 years, worked on YouTube and Google Cloud.`,
  },
  {
    image: Mariam,
    hoverImage: MariamHover,
    fullname: "Mariam",
    proffesion: "Project Management",
    description: `Degree in psychology, background in Tech & 3+ years experience in project management`,
  },
  {
    image: Armand,
    hoverImage: ArmandHover,
    fullname: "Armand",
    proffesion: "PR",
    description: `Copywriter & Journalist with 10+ years of experience`,
  },
  {
    image: Gari,
    hoverImage: GariHover,
    fullname: "Gari",
    proffesion: "Graphic Design",
    description: `A real team player constantly looking to improve, with 8+ years of experience in graphic design.`,
  },
  {
    image: DavidZ,
    hoverImage: DavidZHover,
    fullname: "David",
    proffesion: "Community Strategy",
    description: `GameFi Designer, over 8 years of experience in international scale digital marketing & communications, 6+ years in multi-industry C suits`,
  },
  {
    image: Tako,
    hoverImage: TakoHover,
    fullname: "Tako",
    proffesion: "Community Management",
    description: ``,
  },
  {
    image: Giorgi,
    hoverImage: GiorgiHover,
    fullname: "Giorgi",
    proffesion: "Community Management",
    description: ``,
  },
  {
    image: Uno,
    hoverImage: UnoHover,
    fullname: "Uno",
    proffesion: "Community Management",
    description: ``,
  },
];

export const Team = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [pagination, setPagination] = useState(1);

  return (
    <div
      id="team"
      className="team-container"
      // style={{ cursor: "url(" + drag + ") 35 35, auto" }}
    >
      <Swiper
        // spaceBetween={-150}
        // slidesPerView={3}
        scrollbar={{ draggable: true }}
        modules={[Navigation, Pagination]}
        pagination={{
          clickable: true,
          type: "custom",
          renderCustom: (swiper, children) => setPagination(children),
        }}   
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
        breakpoints={{
          // when window width is >= 0px
          0: {
            slidesPerView: 3,
            spaceBetween: -50,
          },
          // when window width is >= 1000px
          570: {
            spaceBetween: -150,
            slidesPerView: 3,
          },
        }}
      >
        <SwiperSlide>
          <div className="first-slide">
            <h3>meet </h3>
            <h3>our team</h3>
            <p>Inspired by the promise of web3</p>
          </div>
        </SwiperSlide>
        {team.map((value, index) => (
          <SwiperSlide key={index}>
            <div className="single-slide slider-image">
              <img
                src={value.image}
                loading="lazy"
                onMouseOver={(e) => (e.currentTarget.src = value.hoverImage)}
                onMouseOut={(e) => (e.currentTarget.src = value.image)}
                alt="team member"
              />
              <h4>{value.fullname}</h4>
              <h5>{value.proffesion}</h5>
              <p>{value.description}</p>
              <span></span>
            </div>
          </SwiperSlide>
        ))}
        <img
          ref={nextRef}
          className={
            pagination !== 11
              ? "team-right-arrow"
              : "team-right-arrow opacityZero"
          }
          src={teamArrow}
          alt="arrow"
        />
        <img
          ref={prevRef}
          className={
            pagination !== 1 ? "team-left-arrow" : "team-left-arrow opacityZero"
          }
          src={teamArrow}
          alt="arrow"
        />
      </Swiper>
    </div>
  );
};
