import "../css/footer.css";
import { HashLink as Link } from "react-router-hash-link";
import { useNavigate, useLocation } from "react-router-dom";
import { HOME_PATH, PRESS_PATH, PRIVACY_PATH } from "../constants";

// images
import smallLogo from "../assets/smallLogo.webp";
import discord from "../assets/discord.webp";
import twitter from "../assets/twitter.webp";
import instagram from "../assets/instagram.webp";

const DesktopFooter = ({
  navigate,
  setEmail,
  email,
  subscribe,
  subscribed,
  subscribtionColor,
  subscribtionText,
  location,
}) => {
  return (
    <div
      className={
        location === HOME_PATH
          ? "footer-container-main pad-hidden"
          : "footer-container pad-hidden"
      }
    >
      <div
        className={
          location === HOME_PATH ? "footer-left-side-main" : "footer-left-side"
        }
      >
        <a href="/">
          <img src={smallLogo} alt="logo" />
        </a>
        <p
          className={
            location === HOME_PATH
              ? "content-subscribe-main"
              : "content-subscribe"
          }
        >
          Subscribe to be among the first to know when we officially take flight
        </p>

        <div
          className={
            location === HOME_PATH ? "button-wrapper-main" : "button-wrapper"
          }
        >
          <input
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            value={email}
            type="text"
            name="email"
            placeholder="youremail@email.com"
          />
          <button
            className={
              location === HOME_PATH
                ? "subscribe-button-main"
                : "subscribe-button"
            }
            onClick={subscribe}
          >
            subscribe
          </button>
        </div>
        <span style={{ color: subscribtionColor }}>{subscribtionText}</span>
      </div>
      <div
        className={
          location === HOME_PATH ? "footer-middle-main" : "footer-middle"
        }
      >
        <Link to="/#info">How it works?</Link>
        <Link to="/#team">Team</Link>
        <a href={PRESS_PATH}>Press kit</a>
        {/* <a
          href="https://docs.google.com/document/d/1f5xnd8tsc703mHr668XOb3ndCS5Dmr0r"
          target="_blank"
          rel="noreferrer"
        >
          Privacy policy
        </a> */}
        <a href={PRIVACY_PATH}>Privacy policy</a>
      </div>
      <div
        className={
          location === HOME_PATH
            ? "footer-right-side-main"
            : "footer-right-side"
        }
      >
        <p>
          We kindly ask you to keep in mind that Warp Jump’s primary purpose is
          edutainment. While the opinions of our hosts, analysts and whales are
          their true, unfiltered opinions - they are not investment advice.
          Always do your own research.
        </p>
        <div
          className={
            location === HOME_PATH
              ? "footer-social-wrapper-main"
              : "footer-social-wrapper"
          }
        >
          <a
            href="https://discord.gg/q95aaU5nZ6"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={
                location === HOME_PATH
                  ? "footer-social-logo-main"
                  : "footer-social-logo"
              }
              src={discord}
              alt="discord"
            />
          </a>
          <a
            href="https://www.instagram.com/warpjumptv/?hl=en"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={
                location === HOME_PATH
                  ? "footer-social-logo-main"
                  : "footer-social-logo"
              }
              src={instagram}
              alt="instagram"
            />
          </a>
          <a
            href="https://twitter.com/warpjumptv"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={
                location === HOME_PATH
                  ? "footer-social-logo-main"
                  : "footer-social-logo"
              }
              src={twitter}
              alt="twitter"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

const PadFooter = ({
  setEmail,
  email,
  subscribe,
  subscribed,
  subscribtionColor,
  subscribtionText,
}) => {
  return (
    <div className="footer-container web-hidden mob-hidden">
      <div className="footer-left-side-pad">
        <a href="/">
          <img src={smallLogo} alt="logo" />
        </a>
        <p className="content-subscribe-pad">
          Subscribe to be among the first to know when we officially take flight
        </p>
        <div className="button-wrapper-pad">
          <input
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            value={email}
            type="text"
            name="email"
            placeholder="youremail@email.com"
          />
          <button className="subscribe-button-pad" onClick={subscribe}>
            subscribe
          </button>
        </div>
        <span style={{ color: subscribtionColor }}>{subscribtionText}</span>
      </div>
      <div className="footer-middle-pad">
        <Link to="/#info">How it works?</Link>
        <Link to="/#team">Team</Link>
        <a href={PRESS_PATH}>Press kit</a>
        <a
          href="https://docs.google.com/document/d/1f5xnd8tsc703mHr668XOb3ndCS5Dmr0r"
          target="_blank"
          rel="noreferrer"
        >
          Privacy policy
        </a>
      </div>
      <div className="footer-right-side-pad">
        <p>
          We kindly ask you to keep in mind that Warp Jump’s primary purpose is
          edutainment. While the opinions of our hosts, analysts and whales are
          their true, unfiltered opinions - they are not investment advice.
          Always do your own research.
        </p>
        <div className="footer-social-wrapper-pad">
          <a
            href="https://discord.gg/q95aaU5nZ6"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="footer-social-logo-pad"
              src={discord}
              alt="discord"
            />
          </a>
          <a
            href="https://www.instagram.com/warpjumptv/?hl=en"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="footer-social-logo-pad"
              src={instagram}
              alt="instagram"
            />
          </a>
          <a
            href="https://twitter.com/warpjumptv"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="footer-social-logo-pad"
              src={twitter}
              alt="twitter"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

const MobFooter = ({
  setEmail,
  email,
  subscribe,
  subscribed,
  subscribtionColor,
  subscribtionText,
}) => {
  return (
    <div className="footer-container-mob non-mob-hidden">
      <div className="footer-left-side-mob">
        <a href="/">
          <img src={smallLogo} alt="logo" />
        </a>
        <p className="content-subscribe-mob">
          Subscribe to be among the first to know when we officially take flight
        </p>

        <div className="button-wrapper-mob">
          <input
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            value={email}
            type="text"
            name="email"
            placeholder="youremail@email.com"
          />
          <button className="subscribe-button-mob" onClick={subscribe}>
            subscribe
          </button>
        </div>
        <span style={{ color: subscribtionColor }}>{subscribtionText}</span>
      </div>
      <div className="footer-middle-mob">
        <Link to="/#info">How it works?</Link>
        <Link to="/#team">Team</Link>
        <a href={PRESS_PATH}>Press kit</a>
        <a
          href="https://docs.google.com/document/d/1f5xnd8tsc703mHr668XOb3ndCS5Dmr0r"
          target="_blank"
          rel="noreferrer"
        >
          Privacy policy
        </a>
        {/* <a href={PRIVACY_PATH}>Privacy policy</a> */}
      </div>
      <div className="footer-right-side-mob">
        <p>
          We kindly ask you to keep in mind that Warp Jump’s primary purpose is
          edutainment. While the opinions of our hosts, analysts and whales are
          their true, unfiltered opinions - they are not investment advice.
          Always do your own research.
        </p>
        <div className="footer-social-wrapper-mob">
          <a
            href="https://discord.gg/q95aaU5nZ6"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="footer-social-logo-mob"
              src={discord}
              alt="discord"
            />
          </a>
          <a
            href="https://www.instagram.com/warpjumptv/?hl=en"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="footer-social-logo-mob"
              src={instagram}
              alt="instagram"
            />
          </a>
          <a
            href="https://twitter.com/warpjumptv"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="footer-social-logo-mob"
              src={twitter}
              alt="twitter"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export const Footer = ({
  setEmail,
  email,
  subscribe,
  subscribed,
  subscribtionColor,
  subscribtionText,
}) => {
  const navigate = useNavigate();
  const { pathname: location } = useLocation();

  return (
    <>
      <DesktopFooter
        location={location}
        navigate={navigate}
        setEmail={setEmail}
        email={email}
        subscribe={subscribe}
        subscribed={subscribed}
        subscribtionColor={subscribtionColor}
        subscribtionText={subscribtionText}
      />
      <PadFooter
        setEmail={setEmail}
        email={email}
        subscribe={subscribe}
        subscribed={subscribed}
        subscribtionColor={subscribtionColor}
        subscribtionText={subscribtionText}
      />
      <MobFooter
        setEmail={setEmail}
        email={email}
        subscribe={subscribe}
        subscribed={subscribed}
        subscribtionColor={subscribtionColor}
        subscribtionText={subscribtionText}
      />
    </>
  );
};
